<template>
  <div
    class="incident-list-item"
    :class="{ unread: isUnread }"
    @click.prevent="navigateToIncident"
  >
    <div class="left">
      <incident-thumbnail
        size="large"
        :incident-id="incident.id"
        :incident-owner-id="incident.ownerId"
        :created-by="incident.createdBy"
        :tenant-id="tenantId"
      ></incident-thumbnail>
    </div>
    <div class="right" :key="timeAgoRefreshKey">
      <div class="name">
        {{ displayName }}
      </div>

      <incident-tags :incident="incident"></incident-tags>

      {{ getLastActivityHuman(incident) }}
    </div>
    <div class="super-right" v-if="!hidePin">
      <pin-button :guid="incident.id"></pin-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import organisations from "../../../helpers/organisations";
import Dates from "./../../../mixins/Dates";
import TimeAgoRefresher from "./../../../mixins/TimeAgoRefresher";
import IncidentThumbnail from "../../../components/Incidents/IncidentThumbnail.vue";
import IncidentTags from "../../../components/Incidents/IncidentTags.vue";
import incidents from "../../../api/incidents";
import PinButton from "../../../components/PinButton.vue";
const _ = require("lodash");
export default {
  components: { IncidentThumbnail, IncidentTags, PinButton },
  props: ["incident", "hidePin"],
  mixins: [Dates, TimeAgoRefresher],
  watch: {
    incident() {
      this.$forceUpdate();
    }
  },
  data() {
    return {
      // membership: [],
      // isLoadingMembership: false,
      // groupMembers: [],
      // isLoadingGroupMember: false,
    };
  },
  created() {
    // this.getMembership();
    // this.getGroupMembers();
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
      presences: "presences",
    }),

    ...mapGetters("incidents", {
      searchQuery: "searchQuery",
      unreadIncidents: "unreadIncidents",
    }),

    isLoadingMembership() {
      return this.incident.isLoadingMembership !== false;
    },

    membership() {
      return this.incident.membership ? this.incident.membership : [];
    },

    isLoadingGroupMembers() {
      return this.incident.isLoadingGroupMembers !== false;
    },

    groupMembers() {
      return this.incident.groupMembers ? this.incident.groupMembers : [];
    },

    tenantId() {
      let organisation = this.getOrganisationById(this.incident.ownerId);
      return organisations.getOrganisationTenantId(organisation);
    },

    displayName() {
      if (this.incident.displayName && this.incident.displayName.length) {
        return this.incident.displayName;
      }
      return "Unknown";
    },

    isUnread() {
      return _.filter(this.unreadIncidents, { id: this.incident.id }).length;
    },

    myMembership() {
      if (!this.membership) {
        return null;
      }

      let myPresenceIds = _.map(this.presences, "id");
      let intersection = _.intersection(
        myPresenceIds,
        _.map(this.membership, "id")
      );
      if (intersection.length) {
        return _.first(intersection);
      }
      return null;
    },

    myGroupMember() {
      if (this.myMembership) {
        return _.find(this.groupMembers, (groupMember) => {
          return groupMember.presenceId == this.myMembership;
        });
      }
      return null;
    },
  },
  methods: {
    getOrganisationById(id) {
      return _.find(this.organisations, { id });
    },

    getIncidentUrl(incident) {
      return "/lightspeed/incidents/" + this.tenantId + "/" + incident.id;
    },

    navigateToIncident() {
      // return false;
      this.$router.push(this.getIncidentUrl(this.incident));
    },

    getMembership() {
      let vm = this;
      vm.isLoadingMembership = true;
      incidents
        .getMembershipForIncident(this.tenantId, this.incident.id)
        .then((r) => {
          vm.isLoadingMembership = false;
          vm.membership = r.data;
        })
        .catch((e) => {
          console.log(e);
          // vm.$message.error("Error loading membership");
        });
    },

    getGroupMembers() {
      let vm = this;
      vm.isLoadingGroupMembers = true;
      incidents
        .getGroupMembersForIncident(this.tenantId, this.incident.id)
        .then((r) => {
          vm.isLoadingGroupMembers = false;
          vm.groupMembers = r.data;
        })
        .catch((e) => {
          console.loog(e);
          // vm.$message.error("Error loading group members");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.incident-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 45px;
    text-align: center;
  }

  .right {
    padding-left: 15px;
    flex-grow: 1;
  }

   .super-right {
    padding-left: 5px;
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  .name {
    font-weight: 500;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  &.unread {
    .name {
      font-weight: 700 !important;
      color: #000;
    }
    font-weight: 500;
    color: #000;
  }
}
</style>